import styles from "./Warscroll.module.scss";

const diceParse = /(\d*)D?(\d*)\+?(\d*)/

const roll = (sides = 6) => Math.floor(Math.random() * sides) + 1
const swing = (totalAttacks, toHit, toWound, save, rend, damage, champion, dice, crits) => {
    const diceMatch = damage.match(diceParse)
    let numberOfDice = parseInt(diceMatch[1]) || 1;
    let sidesOfDice = parseInt(diceMatch[2]) || 1;
    let flatDamage = parseInt(diceMatch[3]) || 0;
    const doneDamage = [...Array(numberOfDice)]
        .map(a => roll(sidesOfDice))
        .reduce((a, b) => a + b, flatDamage)
    const randomAttacks = [...Array(totalAttacks)].map(a => roll(dice)).reduce((a, b) => a + b, 0) + champion
    const hits = [...Array(randomAttacks)].map(a => roll()).filter(a => a >= toHit)
    const critHits = hits.filter(r => r === 6).length
    const calcHits = crits.twoHits ? hits.length + critHits : (crits.wound || crits.mortals ? hits.length - critHits : hits.length)
    const wounds = [...Array(calcHits)].map(a => roll()).filter(a => a >= toWound)
    const missedSaves = [...Array(crits.wound ? wounds.length + critHits : wounds.length)].map(a => roll()).filter(a => a < (save + rend))
    const damageDone = [...Array(crits.mortals ? missedSaves.length + critHits : missedSaves.length)].map(a => doneDamage).reduce((a, b) => a + b, 0)
    return damageDone
}
const swings = (totalAttacks, toHit, toWound, save, rend, damage, champion, dice, crits) => {
    const amount = 5000
    return Math.round([...Array(amount)].map(a => swing(totalAttacks, toHit, toWound, save, rend, damage, champion, dice, crits)).reduce((a, b) => a + b, 0) / amount)
}
const MathWeapon = ({squadSize, champion, attacks, toHit, toWound, rend, damage, abilities = []}) => {
    let mortals = abilities.includes("Crit (Mortal)")
    let twoHits = abilities.includes("Crit (2 Hits)")
    let wound = abilities.includes("Crit (Auto-wound)")
    let attacksRandom = attacks.match(diceParse);
    let dice = parseInt(attacksRandom[2]) || 0
    const totalAttacks = (squadSize * (parseInt(attacksRandom[1]) || 1))
    const crits = {twoHits, mortals, wound};

    return <div className={styles.mathHammer}>
        <div>{dice > 1 ? totalAttacks + "D" + dice + " " : totalAttacks + champion + " "}
            Attacks from Squad against the following saves
        </div>

        {rend <= 5 ?
            <div>2+: {swings(totalAttacks, toHit, toWound, 2, rend, damage, champion, dice, crits)}</div> : null}
        {rend <= 4 ?
            <div>3+: {swings(totalAttacks, toHit, toWound, 3, rend, damage, champion, dice, crits)}</div> : null}
        {rend <= 3 ?
            <div>4+: {swings(totalAttacks, toHit, toWound, 4, rend, damage, champion, dice, crits)}</div> : null}
        {rend <= 2 ?
            <div>5+: {swings(totalAttacks, toHit, toWound, 5, rend, damage, champion, dice, crits)}</div> : null}
        {rend <= 1 ?
            <div>6+: {swings(totalAttacks, toHit, toWound, 6, rend, damage, champion, dice, crits)}</div> : null}
        {rend <= 0 ?
            <div>No Save: {swings(totalAttacks, toHit, toWound, 7, rend, damage, champion, dice, crits)}</div> : null}
    </div>
}
export default MathWeapon