import styles from "./Warscroll.module.scss";
import {Autocomplete, Button, InputLabel, TextField} from "@mui/material";
import {useState} from "react";
import Warscroll from "./Warscroll";

const Arena = ({warscrollData}) => {
    const [unit1, setUnit1] = useState();
    const [unit2, setUnit2] = useState();
    const fight = () => {
        const weps = unit1.weapons.filter(w => w.type === "melee")

    }
    return <div>
        <div className={styles.top}>
            <div className={styles.selectGroup}>
                <InputLabel id="unit-label">Unit 1</InputLabel>
                <Autocomplete
                    id="unit-selector"
                    options={warscrollData}
                    getOptionLabel={(option) => option.name}
                    value={unit1}
                    filterSelectedOptions
                    onChange={(_, value) => setUnit1(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Unit"
                        />
                    )}
                />
            </div>
            <h2>VS</h2>
            <div className={styles.selectGroup}>
                <InputLabel id="unit-label">Unit 2</InputLabel>
                <Autocomplete
                    id="unit-selector"
                    options={warscrollData}
                    getOptionLabel={(option) => option.name}
                    value={unit2}
                    filterSelectedOptions
                    onChange={(_, value) => setUnit2(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Unit"
                        />
                    )}
                />

            </div>
        </div>
        <div style={{justifyContent: "center", display: "flex", margin: "5px"}}>
            <Button variant="contained">Fight</Button>
        </div>
        {!!unit1 && !!unit2 ? <div style={{display: "flex"}}>
                <Warscroll scroll={{...unit1, abilities: [], keywords: [], baseSize: false, image: false, notes: false}}/>
                <Warscroll scroll={{...unit2, abilities: [], keywords: [], baseSize: false, image: false, notes: false}}/>
            </div>
            : null}

    </div>
}

export default Arena