import styles from "./Warscroll.module.scss";
import MathWeapon from "./MathWeapon";

const Weapons = ({weapons, type, squadSize, keywords, modifications}) => {
    if (weapons.length < 1) return null;
    return <>
        <table className={styles.table}>
            <thead>
            <tr>
                <th>{type}</th>
                {type === "Ranged Weapons" ? <th>Range</th> : null}
                <th>Attacks</th>
                <th>Hit</th>
                <th>Wound</th>
                <th>Rend</th>
                <th>Damage</th>
            </tr>
            </thead>
            <tbody>
            {weapons.map(weapon => {
                const calculatedToHit = Math.max(parseInt(weapon.hit) + modifications.hitChange, 2);
                const calculatedToWound = Math.max(parseInt(weapon.wound) + modifications.woundChange, 2);
                const calculatedRend = weapon.rend === "-" ? modifications.rendChange : (parseInt(weapon.rend) + modifications.rendChange);
                return <>
                    <tr>
                        <td>
                            <div className={styles.weaponName}>{weapon.name}</div>
                            {weapon.abilities?.map(a => <div className={styles.weaponAbility}>{a}</div>)}</td>
                        {weapon.range ? <td>{weapon.range}</td> : null}
                        <td>{weapon.attacks}</td>
                        <td>{calculatedToHit + "+"}</td>
                        <td>{calculatedToWound + "+"}</td>
                        <td>{calculatedRend ? calculatedRend : "-"}</td>
                        <td>{weapon.damage}</td>
                    </tr>
                    {modifications.math ? <tr>
                        <td colSpan={8}><MathWeapon attacks={weapon.attacks}
                                                    rend={calculatedRend}
                                                    toHit={calculatedToHit}
                                                    squadSize={modifications.reinforce ? squadSize * 2 : squadSize}
                                                    champion={keywords.includes("Champion") ? 1 : 0}
                                                    abilities={weapon.abilities}
                                                    damage={weapon.damage}
                                                    toWound={calculatedToWound}/>
                        </td>
                    </tr> : null}

                </>;
            })}

            </tbody>
        </table>
    </>;

}
export default Weapons
