import {Button} from "@mui/material";

const Tiers = ({warscrollData})=>{
    const factions = [...new Set(warscrollData.flatMap(s=>s.faction))].filter(f=>!f.includes("Grand Alliance")).sort()
    return <div style={{flexDirection:"column", display:"flex"}}>
        {factions.map(f=><Button href={"tier/"+f.toLocaleLowerCase().replace(/\s+/g, '-')}>
            {f}
        </Button>)}
    </div>
}
export default Tiers