import './index.css';
import * as React from "react";
import {createRoot} from "react-dom/client";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import warscrollData from './warscrollData.json'
import abilityData from './abilities.json'
import metadata from './metadata.json'
import Warscrolls from "./components/Warscrolls";
import Arena from "./components/Arena";
import oldWarscrollData from './old/warscrollData.json'
import oldAbilityData from './old/abilities.json'
import oldMetadata from './old/metadata.json'
import Tierlist from "./components/Tierlist";
import Tiers from "./components/Tiers";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Warscrolls warscrollData={warscrollData.sort((a, b) => a.name.localeCompare(b.name))}
                             abilityData={abilityData}
                             metadata={metadata}/>,
    }, {
        path: "/arena",
        element: <Arena warscrollData={warscrollData.sort((a, b) => a.name.localeCompare(b.name))}/>,
    }, {
        path: "/old",
        element: <Warscrolls warscrollData={oldWarscrollData.sort((a, b) => a.name.localeCompare(b.name))}
                             abilityData={oldAbilityData}
                             metadata={oldMetadata}/>,
    }, {
        path: "/tier",
        element: <Tiers warscrollData={warscrollData}/>,
    }, {
        path: "/tier/:faction",
        element: <Tierlist warscrollData={warscrollData}/>,
    }
]);

createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);
