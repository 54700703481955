import styles from "./Warscroll.module.scss";
import * as PropTypes from "prop-types";
import Ability from "./Ability";

const Abilities = ({abilities = [],wargear}) => {
    return <div className={styles.abilities}>
        {wargear ? wargear:null}
        {abilities.map(ability =>
            <Ability ability={ability}/>
        )}
    </div>
}
Abilities.propTypes = {
    desc: PropTypes.array,
    abilities: PropTypes.array
}

export default Abilities